<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-3">
        <v-text-field
          type="text"
          v-model="params.identification"
          outlined
          dense
          hide-details
          single-line
          label="NIT/RUT"
          v-on:keyup.enter="search"
        ></v-text-field>
      </div>
      <div class="col-12 col-md-3">
        <v-text-field
          type="text"
          v-model="params.companyName"
          outlined
          dense
          single-line
          hide-details
          label="Nombre comercial"
          v-on:keyup.enter="search"
        ></v-text-field>
      </div>
      <div class="col-12 col-md-3">
        <v-select
          v-model="params.fairId"
          :items="fairs"
          item-text="name"
          item-value="id"
          @change="getPlacesAndStands"
          outlined
          dense
          hide-details
          label="Feria"
        ></v-select>
      </div>
      <div class="col-12 col-md-3">
        <v-select
          v-model="params.placeId"
          :items="places"
          item-text="name"
          item-value="id"
          outlined
          dense
          single-line
          hide-details
          label="Pabellón"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-3">
        <v-select
          v-model="params.standId"
          :items="stands"
          item-text="code"
          item-value="id"
          outlined
          dense
          single-line
          hide-details
          label="Identificador del Stand"
        ></v-select>
      </div>
      <div class="col-12 col-md-3 d-flex">
        <v-text-field
          type="number"
          class="mr-2"
          v-model="params.fromArea"
          outlined
          dense
          single-line
          hide-details
          label="M2 desde"
          v-on:keyup.enter="search"
        ></v-text-field>
        <v-text-field
          type="number"
          v-model="params.toArea"
          outlined
          dense
          single-line
          hide-details
          label="M2 hasta"
          v-on:keyup.enter="search"
        ></v-text-field>
      </div>
      <div class="col-12 col-md-3">
        <v-select
          v-model="params.countryId"
          :items="countries"
          item-text="name"
          item-value="id"
          outlined
          dense
          single-line
          hide-details
          label="País"
        ></v-select>
      </div>
      <div class="col-12 col-md-3">
        <v-select
          v-model="params.bookingStatus"
          :items="bookingStatuses"
          item-text="description"
          item-value="code"
          outlined
          dense
          single-line
          hide-details
          label="Estado"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-3">
        <date-picker label="Fecha de inicio" v-model="params.startDate"></date-picker>
      </div>
      <div class="col-12 col-md-3">
        <date-picker label="Fecha de fin" v-model="params.endDate"></date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="search"
            class="white--text text-none mr-2 elevation-0">
            <v-icon
              left
              dark>
              fa-search
            </v-icon>
            Buscar
          </v-btn>
          <v-btn
            color="default"
            @click="clean"
            class="text-none elevation-0">
            <v-icon
              left
              dark>
              fa-broom
            </v-icon>
            Limpiar
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { BOOKING_STATUSES, COUNTRIES_URL, PLACE_URL, PUBLIC_FAIRS_URL, STAND_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import DatePicker from '@/components/date-picker/DatePicker'

export default {
  name: 'BookingFilters',
  components: { DatePicker },
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  async created () {
    try {
      this.showLoader()
      await Promise.all([this.getFairs(), this.getCountries(), this.getBookingStatuses()])
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      startDate: null,
      endDate: null,
      fairs: [],
      bookingStatuses: [],
      places: [],
      stands: [],
      countries: [],
      fairName: null
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin, formOperationsMixin],
  methods: {
    async getFairs () {
      const response = await this.get(PUBLIC_FAIRS_URL + '/all')
      this.fairs = [{
        id: '',
        name: 'Ninguna'
      }].concat(response.data)
    },
    async getCountries () {
      const response = await this.get(COUNTRIES_URL)
      this.countries = response.data
    },
    async getBookingStatuses () {
      const response = await this.get(BOOKING_STATUSES)
      this.bookingStatuses = [{
        code: '',
        description: 'Ninguno'
      }].concat(response.data)
    },
    async getPlacesAndStands () {
      try {
        this.showLoader()
        const params = { fairId: this.params.fairId }
        const fair = this.fairs.find((f) => {
          return f.id === this.params.fairId
        })
        if (fair) {
          this.fairName = fair.name
        }
        const placesResponse = await this.get(PLACE_URL + '/booked', {
          params: params
        })
        this.places =
          [{
            id: '',
            name: 'Ninguno'
          }].concat(placesResponse.data)
        const standsResponse = await this.get(STAND_URL + '/booked', {
          params: { fairId: this.params.fairId }
        })
        this.stands = [{
          id: '',
          code: 'Ninguno'
        }].concat(standsResponse.data)
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    clean () {
      this.$emit('clean')
    },
    search () {
      this.$emit('search', this.fairName)
    }
  }
}
</script>
